export default {
    methods: {
        posthogIdentify(userData) {
            try {
                if (process.env.VUE_APP_POSTHOG_APP_KEY) {
                    this.$posthog.identify(userData.email, {
                        email: userData.email,
                        first_name: userData.first_name,
                        last_name: userData.last_name,
                    })
                }
            } catch (error) {
                console.error(error);
            }
        }
    }
}
