<template>
    <AuthFormContainer title="Log into your account">
        <div class="login-form">

            <div>
                <CNInput v-model="formData.email" :invalid="!!validationErrors.email"
                         @blur="handleChangeField('email', $event)" maxlength="255" label="Enter Email"/>

                <CFormText v-if="validationErrors.email" :style="`color: ${validationErrors.email && 'red'}`">
                    {{ validationErrors.email }}
                </CFormText>
            </div>

            <div>
                <CNInputPassword :read-only-on-init="false" :model-value="formData.password"
                                 :invalid="!!validationErrors.password" @update:model-value="v => formData.password = v"
                                 label="Enter Password" @blur="handleChangeField('password', $event)"
                                 @keyup.enter="handleSubmit"/>
                <CFormText v-if="validationErrors.password" :style="`color: ${validationErrors.password && 'red'}`">
                    {{ validationErrors.password }}
                </CFormText>
            </div>

            <div class="login-form__checkbox">
                <input class="form-check-input" type="checkbox" :value="formData.remember_me"
                       :checked="formData.remember_me" @change="formData.remember_me = !formData.remember_me"/>
                Remember me
            </div>

            <forgot-password-modal class="login-form__link"/>

            <CButton class="login-form__submit" color="primary" @click="handleSubmit">
                <CSpinner v-if="submitLoading" class="login-form__spinner"/>
                <span v-else>Log in</span>
            </CButton>

            <router-link to="/contact-us"> Don't have an account? Contact us</router-link>
        </div>
    </AuthFormContainer>

    <CModal class="overflow-modal" :visible="openedModal === 'verify-error'" backdrop="static"
            @close="openedModal = null">
        <div class="app-modal login-form__modal">

            <div class="overflow-modal__close-bar">
                <CIcon name="cil-x" @click="openedModal = null"/>
            </div>

            <div class="app-modal__icon">
                <CIcon class="login-form__modal-icon" name="cilBriefcase"/>
            </div>

            <h4 class="app-modal__title">Your e-mail is not verified</h4>

            <div class="app-modal__buttons">
                <CButton style="min-width: 200px" variant="outline" color="primary" @click="openedModal = null">
                    Cancel
                </CButton>

                <CButton style="min-width: 200px" color="primary" @click="submitVerificationInvite">
                    Send Invite
                </CButton>
            </div>

        </div>
    </CModal>

    <CModal class="overflow-modal" :visible="openedModal === 'verify-email' && $route.query.companyName"
            backdrop="static" @close="onModalClose">
        <div class="app-modal login-form__modal">

            <div class="overflow-modal__close-bar">
                <CIcon name="cil-x" @click="openedModal = null"/>
            </div>

            <div class="app-modal__icon">
                <CIcon class="login-form__modal-icon" name="cilBriefcase"/>
            </div>

            <h4 class="app-modal__title">Welcome to Contracts Connected</h4>

            <p class="app-modal__text">
                You've successfully created an account for {{ $route.query.companyName }}.
                Before getting started, please check your email to verify your email address
            </p>

            <div class="app-modal__buttons">
                <CButton style="min-width: 200px" color="primary" @click="onModalClose">OK</CButton>
            </div>

        </div>
    </CModal>

    <CModal class="overflow-modal" :visible="openedModal === 'verify-email' && $route.query.company"
            backdrop="static" @close="onModalClose">
        <div class="app-modal login-form__modal">

            <div class="overflow-modal__close-bar">
                <CIcon name="cil-x" @click="openedModal = null"/>
            </div>

            <div class="app-modal__icon">
                <CIcon class="login-form__modal-icon" name="cilBriefcase"/>
            </div>

            <h4 class="app-modal__title">Welcome to Contracts Connected</h4>

            <p class="app-modal__text">
                You've successfully created an account for {{ $route.query.company }}.
                Before getting started, please check your email to verify your email address
            </p>

            <div class="app-modal__buttons">
                <CButton style="min-width: 200px" color="primary" @click="onModalClose">Check Email</CButton>
            </div>

        </div>
    </CModal>

    <CModal class="overflow-modal" :visible="openedModal === 'invite-user-success'" backdrop="static"
            @close="onModalClose">
        <div class="app-modal login-form__modal">

            <div class="overflow-modal__close-bar">
                <CIcon name="cil-x" @click="openedModal = null"/>
            </div>

            <div class="app-modal__icon">
                <CIcon class="login-form__modal-icon" name="cilCheckCircle"/>
            </div>

            <h4 class="app-modal__title">Welcome to Contracts Connected!</h4>

            <p class="app-modal__text">
                {{ $route.query.message }}
            </p>

            <div class="app-modal__buttons">
                <CButton style="min-width: 200px" color="primary" @click="onModalClose">OK</CButton>
            </div>

        </div>
    </CModal>

    <CModal class="overflow-modal" :visible="openedModal === 'invite-company-success'" backdrop="static"
            @close="onModalClose">
        <div class="app-modal login-form__modal">

            <div class="overflow-modal__close-bar">
                <CIcon name="cil-x" @click="openedModal = null"/>
            </div>

            <div class="app-modal__icon">
                <CIcon class="login-form__modal-icon" name="cilBriefcase"/>
            </div>

            <h4 class="app-modal__title">Welcome to Contracts Connected!</h4>

            <p class="app-modal__text">
                {{ $route.query.message }}
            </p>

            <div class="app-modal__buttons">
                <CButton style="min-width: 200px" color="primary" @click="onModalClose">OK</CButton>
            </div>

        </div>
    </CModal>

    <CModal class="overflow-modal" :visible="openedModal === 'email_verified'" backdrop="static" @close="onModalClose">
        <div class="app-modal login-form__modal">

            <div class="overflow-modal__close-bar">
                <CIcon name="cil-x" @click="openedModal = null"/>
            </div>

            <div class="app-modal__icon">
                <img class="scope-upload__modal-icon" src="@/assets/images/well-done.svg" alt="Done"/>
            </div>

            <h4 class="app-modal__title">Email Verified</h4>

            <p class="app-modal__text">
                Your account has been verified successfully!
            </p>

            <div class="app-modal__buttons">
                <CButton style="min-width: 200px" color="primary" @click="onModalClose">OK</CButton>
            </div>

        </div>
    </CModal>

    <CModal class="overflow-modal" :visible="openedModal === 'blocked-company'" backdrop="static" @close="onModalClose">
        <div class="app-modal login-form__modal">

            <div class="overflow-modal__close-bar">
                <CIcon name="cil-x" @click="openedModal = null"/>
            </div>

            <div class="app-modal__icon">
                <CIcon class="login-form__modal-icon" name="cilFaceDead"/>
            </div>

            <h4 class="app-modal__title">Account suspended</h4>

            <p class="app-modal__text">
                Your account has been suspended. Please contact administrator
            </p>

            <div class="app-modal__buttons">
                <CButton style="min-width: 200px" color="primary" @click="onModalClose">OK</CButton>
            </div>

        </div>
    </CModal>

    <CModal class="overflow-modal" :visible="openedModal === 'decline'" backdrop="static" @close="onModalClose">
        <div class="app-modal login-form__modal">

            <div class="overflow-modal__close-bar">
                <CIcon name="cil-x" @click="openedModal = null"/>
            </div>

            <div class="app-modal__icon">
                <CIcon class="login-form__modal-icon" name="cilBriefcase"/>
            </div>

            <h4 class="app-modal__title">Invitation declined</h4>

            <p class="app-modal__text">
                Your invitation has been declined. If you have any questions, feel free to ask. Thank you.
            </p>

            <div class="app-modal__buttons">
                <CButton style="min-width: 200px" color="primary" @click="onModalClose">OK</CButton>
            </div>

        </div>
    </CModal>
</template>

<script>
import {mapActions} from 'vuex'
import validator from '@/utils/validator'
import rules from '@/utils/validator/rules'
import {parseError} from '@/utils/api'
import ForgotPasswordModal from '@/components/Modals/ForgotPasswordModal'
import EmailVerificationNotification from '@/components/Modals/EmailVerificationNotification'
import modalComponent from '@/mixin/modalComponent'
import AuthFormContainer from "@/components/AuthFormContainer.vue";
import CNInput from "@/components/ui/CNInput/CNInput.vue";
import CNInputPassword from "@/components/ui/CNInputPassword/CNInputPassword.vue";
import posthogHelper from "@/mixin/posthogHelper";

export default {
    name: 'Login',
    components: {
        CNInputPassword, CNInput, AuthFormContainer,
        ForgotPasswordModal,
        EmailVerificationNotification,
    },
    mixins: [modalComponent, posthogHelper],
    inject: ['toast'],
    data() {
        return {
            submitLoading: false,
            formData: {
                email: '',
                password: '',
                remember_me: false,
            },

            declineReason: '',

            validator: {},
            validationErrors: {},

            visPass: true,

            openedModal: ''
        }
    },
    created() {
        this.setRules();
    },
    mounted() {
        if (this.$route.query.modal) {
            this.openModal(this.$route.query.modal)
        }

        if (this.$route.query.type && this.$route.query.type === 'email_verified') {
            this.openModal('email_verified')
        }

        if (this.$route.query.declineLink) {
            this.openModal('decline')
        }
    },
    methods: {
        ...mapActions(['login']),
        openModal(name) {
            this.openedModal = name;
        },
        onModalClose() {
            this.openedModal = '';
            this.$router.replace({'query': null});
        },
        submitVerificationInvite() {
            this.submitLoading = true
            this.$http.auth.sendVerificationLink({email: this.formData.email})
                .then(() => {
                    this.toast('success', 'The email has been resent to your mail')
                    this.openedModal = null;
                })
                .catch(err => {
                    this.toast('error', err.message)
                })
                .finally(() => {
                    this.submitLoading = false
                })
        },
        handleSubmit() {
            const validationResult = this.validator.validateAll(this.formData)

            if (!validationResult.hasErrors) {
                this.submitLoading = true
                this.login(this.formData)
                    .then(response => {
                        this.posthogIdentify(response.data.data);

                        const storageSavedRouteKey = 'failedRedirection'

                        if (localStorage.getItem(storageSavedRouteKey)) {
                            this.$router.push(localStorage.getItem(storageSavedRouteKey))
                            localStorage.removeItem(storageSavedRouteKey)
                            return true
                        }

                        this.$router.push({name: 'ProjectsList'})
                    })
                    .catch((err) => {
                        err = parseError(err)
                        if (err.status === 403) {
                            const blockedAccountMessage = err.message === 'Your account has been suspended. Please contact administrator';
                            blockedAccountMessage ? this.openModal('blocked-company') : this.openModal('verify-error');
                        } else if (err.status === 422) {
                            this.validationErrors = err.validationMessages
                        } else if (err.status === 401) {
                            this.validationErrors = {
                                email: err.message,
                                password: err.message,
                            }
                        } else {
                            this.toast('error', err.message)
                        }
                    })
                    .finally(() => {
                        this.submitLoading = false
                    })
            } else {
                this.validationErrors = validationResult.validationErrors
            }
        },
        handleChangeField(field, value) {
            this.validationErrors[field] = this.validator.validate(
                field,
                value,
            )
        },
        setRules() {
            this.validator = validator({
                email: [rules.required, rules.email],
                password: [rules.required],
            })
        },
    },
    beforeUnmount() {
        localStorage.removeItem('failedRedirection')
    }
}
</script>

<style lang="scss">
.login-form {
    display: flex;
    flex-direction: column;
    gap: 36px;

    & .cn-form-input_focused {
        border: 2px solid green !important;
    }

    &__modal {
        width: 688px;

        & .overflow-modal__close-bar {
            padding: 0 !important;
        }

        &-icon {
            height: 68px !important;
            width: 68px !important;
            color: #BAC6CF;
        }
    }

    &__checkbox {
        font-size: 14px;
        display: flex;
        gap: 8px;
        align-items: center;
        color: #1C262F;

        & .form-check-input {
            margin: 0;
        }
    }

    &__spinner {
        height: 20px;
        width: 20px;
    }

    &__submit {
        width: 98px;
        height: 40px;
        display: flex;
        align-items: center;
        justify-content: center;
    }

    &__link {
        font-size: 14px;
        color: #0068AD;
        text-decoration: underline;
        cursor: pointer;
    }

    &__text {
        color: #1C262F;
        font-size: 14px;
    }

    &__question {
        display: flex;
        gap: 5px;
        align-items: center;
    }
}
</style>
