<template>
  <CModal :visible="showModal">
    <CModalHeader>
      <CModalTitle>Verified e-mail</CModalTitle>
    </CModalHeader>
    <CModalBody>
      <p class="text-muted">Your e-mail is not verified</p>
    </CModalBody>

    <CModalFooter>
      <submit-btn color="primary" :loading="submitLoading" @click="handleSubmit">
        Send Invite
      </submit-btn>
      <CButton color="primary" variant="outline"  @click="showModal = false">
        Cancel
      </CButton>
    </CModalFooter>
  </CModal>
</template>

<script>
  export default {
    name: 'EmailVerificationNotificationModal',
    inject: ['toast'],
    props: {
      show: Boolean,
      email: String,
      close: Function,
    },
    mounted() {
      this.showModal = this.show
    },
    data() {
      return {
        showModal: false,
        submitLoading: false,
      }
    },
    watch: {
      show: {
        handler(val) {
          this.showModal = val
        },
      },
      showModal(val) {
        this.$emit('update:show', val)
        if (!val) {
          this.handleClose()
        }
      }
    },
    methods: {
      handleSubmit() {
        this.submitLoading = true
        this.$http.auth.sendVerificationLink({ email: this.email })
          .then(() => {
            this.toast('success', 'The email has been resent to your mail')
            this.showModal = false
          })
          .catch(err => {
            this.toast('error', err.message)
          })
          .finally(() => {
            this.submitLoading = false
          })
      },
      handleClose() {
        this.close()
      }
    }
  }
</script>

<style scoped>

</style>
